import mindMapBackgroundHalfUrl from "@assets/mind_map_background_half.png";
import Button from "@components/Button";
import { Trans, useTranslation } from "react-i18next";
import { Form } from "react-router-dom";

export default function LearnFasterPage() {
  const { t } = useTranslation();

  return (
    <main className="flex h-dvh flex-col space-y-5">
      <div
        className="basis-2/5 bg-cover md:bg-contain"
        style={{
          backgroundImage: `url(${mindMapBackgroundHalfUrl})`,
          backgroundPosition: "center bottom",
          backgroundRepeat: "no-repeat",
        }}
      />

      <div className="custom-container flex basis-3/5 flex-col space-y-5 pb-14">
        <div className="grow content-center space-y-5">
          <h1 className="heading">
            {t("pages.onboarding.learnFaster.heading")}
          </h1>

          <p className="text-center text-lg">
            <Trans
              components={{
                colored: <span className="theme-text-colored font-bold" />,
              }}
              i18nKey="pages.onboarding.short.learnFaster.text"
            />
          </p>
        </div>

        <Form className="shrink-0" method="post">
          <Button title={t("shared.continue")} type="submit" />
        </Form>
      </div>
    </main>
  );
}
