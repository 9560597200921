import { redirect } from "react-router-dom";

const ORDERED_DEFAULT_ONBOARDING_ROUTES = [
  "age",
  "company",
  "gender",
  "topics",
  "learn",
  "learn-faster",
  "productivity",
  "learning",
  "tailored",
  "opportunities",
  "potential",
  "complex",
  "progress",
  "youtube-or-podcasts",
  "youtube-covered",
];

const ORDERED_FEMALE_ONBOARDING_ROUTES = [
  "age",
  "company",
  "topics",
  "learn",
  "learn-faster",
  "personal-growth",
  "relationship-challenge",
  "improve",
  "tailored",
  "learning-opportunities",
  "full-potential",
  "complex-concepts",
  "youtube-content",
  "youtube-covered",
];

const ORDERED_SHORT_ONBOARDING_ROUTES = [
  "age",
  "company",
  "topics",
  "learn",
  "learn-faster",
  "learning-opportunities",
  "productivity",
  "ai-helper",
  "youtube-or-podcasts",
  "youtube-summary",
];

const ORDERED_V1_ONBOARDING_ROUTES = [
  "age",
  "tailored",
  "gender",
  "topics",
  "learn",
  "productivity",
  "success",
  "success-follow-up",
  "advance-skills",
  "full-potential",
  "company",
  "what-you-get",
];

const ORDERED_V2_ONBOARDING_ROUTES = [
  "landing",
  "age",
  "topics",
  "learn",
  "learning-opportunities",
  "productivity",
];

const ORDERED_V3_ONBOARDING_ROUTES = ["age", "personalize-experience"];

const ORDERED_YOUTUBE_ONBOARDING_ROUTES = [
  "usage",
  "content",
  "learn-faster",
  "productivity-environment",
  "complex-concepts",
  "organizing-thoughts",
  "company",
  "engage",
  "covered",
  "learning-opportunities",
  "full-potential",
];

export default async function navigateToNextOnboardingPageAction({
  request,
}: {
  request: Request;
}) {
  const formData = await request.formData();

  // NOTE: Get form data entries:
  // console.log("formData", Object.fromEntries(formData));

  // TODO: Multiple checkbox values are not being captured correctly
  // const topics = formData.getAll("topics");
  // console.log("topics", topics);

  const next = getNext(request.url);

  const currentPage = request.url.split("/").pop()?.split("?")[0];

  if (!next) {
    if (currentPage === "personalize-experience") {
      const personalizeExperience = formData.get(
        "personalize-experience",
      ) as string;

      if (personalizeExperience === "yes") {
        return redirect("/onboarding/company");
      } else {
        return redirect("/onboarding/short/company");
      }
    } else {
      return redirect("/sign-up/password");
    }
  }

  const nextPathname = getNextPathName({ next, url: request.url });

  if (currentPage === "success") {
    const hash = formData.get("success") as string;

    return redirect(`${nextPathname}#${hash}`);
  } else {
    return redirect(nextPathname);
  }
}

function getNext(url: string): string | undefined {
  const pathname = new URL(url).pathname;
  const current = pathname.split("/").pop();

  if (!current) return;

  const orderedOnboardingRoutes = getOrderedOnboardingRoutes(pathname);
  const currentIndex = orderedOnboardingRoutes.indexOf(current);
  const next = orderedOnboardingRoutes[currentIndex + 1];

  return next;
}

function getNextPathName({ next, url }: { next: string; url: string }): string {
  const currentPathname = new URL(url).pathname;
  const paths = currentPathname.split("/");
  const basePathname = paths.slice(0, -1).join("/");
  const nextPathname = basePathname + "/" + next;
  return nextPathname;
}

function getOrderedOnboardingRoutes(pathname: string): string[] {
  if (pathname.includes("onboarding/female/"))
    return ORDERED_FEMALE_ONBOARDING_ROUTES;

  if (pathname.includes("onboarding/short/"))
    return ORDERED_SHORT_ONBOARDING_ROUTES;

  if (pathname.includes("onboarding/youtube/"))
    return ORDERED_YOUTUBE_ONBOARDING_ROUTES;

  if (pathname.includes("onboarding/v1/")) return ORDERED_V1_ONBOARDING_ROUTES;

  if (pathname.includes("onboarding/v2/")) return ORDERED_V2_ONBOARDING_ROUTES;

  if (pathname.includes("onboarding/v3/")) return ORDERED_V3_ONBOARDING_ROUTES;

  return ORDERED_DEFAULT_ONBOARDING_ROUTES;
}
