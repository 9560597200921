import Layout from "@components/Layout";
import ProgressBar from "@components/ProgressBar";
import RadioInputButton from "@components/RadioInputButton";
import { useTranslation } from "react-i18next";
import { Form, useSubmit } from "react-router-dom";

export default function GenderPage() {
  const submit = useSubmit();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form);
  };

  const radioInputButtonClassName = "v1-button-input";

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0 space-y-8">
          <ProgressBar />

          <h1 className="heading">{t("pages.onboarding.gender.heading")}</h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5"
          method="post"
        >
          <RadioInputButton
            className={radioInputButtonClassName}
            label={t("pages.onboarding.gender.male")}
            name="gender"
            onChange={handleChange}
            value="male"
          />

          <RadioInputButton
            className={radioInputButtonClassName}
            label={t("pages.onboarding.gender.female")}
            name="gender"
            onChange={handleChange}
            value="female"
          />

          <RadioInputButton
            className={radioInputButtonClassName}
            label={t("pages.onboarding.gender.other")}
            name="gender"
            onChange={handleChange}
            value="other"
          />
        </Form>
      </div>
    </Layout>
  );
}
