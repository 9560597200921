import Layout from "@components/Layout";
import RadioInputButton from "@components/RadioInputButton";
import { useTranslation } from "react-i18next";
import { Form, useSubmit } from "react-router-dom";

export default function ImprovePage() {
  const submit = useSubmit();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form);
  };

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="heading">
            {t("pages.onboarding.female.improve.heading")}
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5"
          method="post"
        >
          <RadioInputButton
            emoji="🏋️‍♀️"
            label={t("pages.onboarding.female.improve.physicalFitness")}
            name="improve"
            onChange={handleChange}
            value="physical-fitness"
          />

          <RadioInputButton
            emoji="🧠"
            label={t("pages.onboarding.female.improve.mentalHealth")}
            name="improve"
            onChange={handleChange}
            value="mental-health"
          />

          <RadioInputButton
            emoji="👩‍🏭"
            label={t("pages.onboarding.female.improve.careerGrowth")}
            name="improve"
            onChange={handleChange}
            value="career-growth"
          />

          <RadioInputButton
            emoji="🙋‍♀️"
            label={t("pages.onboarding.female.improve.socialConnections")}
            name="improve"
            onChange={handleChange}
            value="social-connections"
          />

          <RadioInputButton
            emoji="💵"
            label={t("pages.onboarding.female.improve.financialStability")}
            name="improve"
            onChange={handleChange}
            value="financial-stability"
          />
        </Form>
      </div>
    </Layout>
  );
}
