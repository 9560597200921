import Layout from "@components/Layout";
import RadioInputButton from "@components/RadioInputButton";
import { useTranslation } from "react-i18next";
import { Form, useSubmit } from "react-router-dom";

export default function PersonalGrowthPage() {
  const submit = useSubmit();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form);
  };

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="heading">
            {t("pages.onboarding.female.personalGrowth.heading")}
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5"
          method="post"
        >
          <RadioInputButton
            emoji="💕"
            label={t(
              "pages.onboarding.female.personalGrowth.emotionalWellBeingStrategies",
            )}
            name="personal-growth"
            onChange={handleChange}
            value="emotional-well-being-strategies"
          />

          <RadioInputButton
            emoji="📚"
            label={t(
              "pages.onboarding.female.personalGrowth.personalDevelopmentBooks",
            )}
            name="personal-growth"
            onChange={handleChange}
            value="personal-development-books"
          />

          <RadioInputButton
            emoji="💡"
            label={t(
              "pages.onboarding.female.personalGrowth.inspirationalStories",
            )}
            name="personal-growth"
            onChange={handleChange}
            value="inspirational-stories"
          />

          <RadioInputButton
            emoji="🧘‍♀️"
            label={t(
              "pages.onboarding.female.personalGrowth.mindfulnessAndMeditationTechniques",
            )}
            name="personal-growth"
            onChange={handleChange}
            value="mindfulness-and-meditation-techniques"
          />
        </Form>
      </div>
    </Layout>
  );
}
