import SubscriptionForm from "@components/SubscriptionForm";
import localizeCurrency from "@helpers/localizeCurrency";
import { useTranslation } from "react-i18next";
import { Plan, StripeCoupon, StripePrice } from "src/types";

interface Props {
  bestOffer?: boolean;
  currency: string;
  isSubmitting: boolean;
  periodAmount: number;
  periodUnit: string;
  plan: Plan;
  setIsSubmitting: (isSubmitting: boolean) => void;
  stripeIntroPriceCoupon: StripeCoupon | undefined;
  stripePrice: StripePrice | undefined;
}

export default function PlanCard({
  bestOffer,
  currency,
  isSubmitting,
  periodAmount,
  periodUnit,
  plan,
  setIsSubmitting,
  stripeIntroPriceCoupon,
  stripePrice,
}: Props) {
  const { t } = useTranslation();

  if (!stripePrice) return null;

  const hasCurrencyOption =
    !!stripePrice.currency_options?.[currency]?.unit_amount_decimal;

  const price = parseFloat(
    hasCurrencyOption
      ? stripePrice.currency_options[currency].unit_amount_decimal
      : stripePrice?.unit_amount_decimal,
  );

  const priceCurrency = hasCurrencyOption ? currency : stripePrice.currency;

  if (!price) return null;

  const pricePerPeriod = price / periodAmount;

  const percentOff = stripeIntroPriceCoupon?.percent_off;
  const introPrice = percentOff && price * (1 - percentOff / 100);
  const introPricePerPeriod = introPrice && introPrice / periodAmount;

  const localizePrice = (price: number | undefined) => {
    if (!price) return "";

    return localizeCurrency({
      amountInCents: price,
      currency: priceCurrency,
    });
  };

  const localizeBilledAt = () => {
    let translationKey = "pages.checkout.plans.";

    switch (plan as string) {
      case "weekly":
        translationKey += "billedWeeklyAt";
        break;
      case "yearly":
        translationKey += "billedAnnuallyAt";
        break;
      default:
        throw new Error(`Unhandled plan: ${plan}`);
    }

    return t(translationKey, { price: localizePrice(price) });
  };

  const localizeSpecialOffer = () => {
    if (!percentOff) return localizeBilledAt();

    let translationKey = "pages.checkout.plans.";

    switch (plan as string) {
      case "weekly":
        translationKey += "firstWeekSpecialOffer";
        break;
      case "yearly":
        translationKey += "firstYearSpecialOffer";
        break;
      default:
        throw new Error(`Unhandled plan: ${plan}`);
    }

    return t(translationKey, {
      price: localizePrice(percentOff ? introPrice : price),
    });
  };

  return (
    <div>
      <div className="group">
        {bestOffer && (
          <div className="theme-plan-card -mb-8 rounded-t-3xl border p-5 pb-12 text-center">
            <p className="text-2xl font-bold uppercase">
              {t("pages.checkout.plans.bestOffer")}
            </p>
          </div>
        )}

        <div className="theme-plan-card rounded-3xl border-3 px-6 pb-12 pt-5 text-center">
          <p className="text-4xl font-bold">{periodAmount}</p>

          <p className="mt-3 text-lg uppercase">
            {t(`pages.checkout.plans.${periodUnit}`, { count: periodAmount })}
          </p>

          {percentOff && (
            <p className="mt-3 text-2xl line-through">
              {localizePrice(pricePerPeriod)}
            </p>
          )}

          <p className="mt-2 text-2xl font-bold">
            {localizePrice(percentOff ? introPricePerPeriod : pricePerPeriod)} /{" "}
            {t(`pages.checkout.plans.${periodUnit}`, { count: 1 })}
          </p>

          {percentOff && (
            <p className="theme-plan-card-gray mt-4 line-through">
              {localizeBilledAt()}
            </p>
          )}

          <p className="theme-plan-card-gray font-semibold">
            {localizeSpecialOffer()}
          </p>

          <SubscriptionForm
            buttonClassName="mt-5 uppercase"
            buttonTitle={t("pages.checkout.plans.subscribeNow")}
            couponId={stripeIntroPriceCoupon?.id}
            currency={priceCurrency}
            isSubmitting={isSubmitting}
            plan={plan}
            priceId={stripePrice.id}
            setIsSubmitting={setIsSubmitting}
          />

          <p className="mt-7">{t("pages.checkout.plans.cancelAnytime")}</p>

          <p className="mt-3 font-bold">
            {t("pages.checkout.plans.moneyBackGuarantee")}
          </p>
        </div>
      </div>
    </div>
  );
}
