import Button from "@components/Button";
import Layout from "@components/Layout";
import ProgressBar from "@components/ProgressBar";
import RadioInputButton from "@components/RadioInputButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, useSubmit } from "react-router-dom";

export default function LearnPage() {
  const [displayVisual, setDisplayVisual] = useState(false);

  const submit = useSubmit();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    if (value === "visual") {
      setDisplayVisual(true);
    } else {
      submit(event.currentTarget.form);
    }
  };

  return (
    <Layout>
      {displayVisual ? (
        <div className="flex h-full flex-col text-center">
          <div className="shrink-0">
            <ProgressBar />
          </div>

          <div className="flex grow flex-col items-center justify-center">
            <span className="text-7xl">💪</span>

            <h1 className="heading my-5">
              {t("pages.onboarding.v1.learn.greatChoiceHeading")}
            </h1>

            <p className="text-lg">
              {t("pages.onboarding.v1.learn.greatChoiceText")}
            </p>
          </div>

          <Form className="shrink-0" method="post">
            <input type="hidden" name="learn" value="visual" />

            <Button title={t("shared.continue")} type="submit" />
          </Form>
        </div>
      ) : (
        <div className="flex h-full flex-col">
          <div className="shrink-0 space-y-8">
            <ProgressBar />

            <h1 className="heading">{t("pages.onboarding.learn.heading")}</h1>
          </div>

          <Form
            className="flex grow flex-col justify-center space-y-5"
            method="post"
          >
            <RadioInputButton
              className="v1-button-input"
              label={t("pages.onboarding.learn.visual")}
              name="learn"
              onChange={handleChange}
              value="visual"
            />

            <RadioInputButton
              className="v1-button-input"
              label={t("pages.onboarding.learn.audio")}
              name="learn"
              onChange={handleChange}
              value="audio"
            />

            <RadioInputButton
              className="v1-button-input"
              label={t("pages.onboarding.learn.reading")}
              name="learn"
              onChange={handleChange}
              value="reading"
            />
          </Form>
        </div>
      )}
    </Layout>
  );
}
