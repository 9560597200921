import Button from "@components/Button";
import Layout from "@components/Layout";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-router-dom";

export default function WhatYouGetPage() {
  const [displayListItems, setDisplayListItems] = useState<boolean>(false);

  const { t } = useTranslation();

  const delaysByTranslationKey = {
    createAiPoweredMindMapsOnAnyTopic: "delay-0",
    chatAndConvertDiscussionsIntoMindMaps: "delay-[500ms]",
    turnAnyLinkContentIntoAMindMapInstantly: "delay-[1000ms]",
    generateImagesEffortlessly: "delay-[1500ms]",
    turnAnyYouTubeVideoIntoAMindMapInstantly: "delay-[2000ms]",
  };

  const translationKeys = Object.keys(delaysByTranslationKey);

  useEffect(() => {
    setDisplayListItems(true);
  }, []);

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0 space-y-5">
          <h1 className="heading">
            {t("pages.onboarding.v1.whatYouGet.heading")}
          </h1>

          <p className="theme-subheading">
            {t("pages.onboarding.v1.whatYouGet.subheading")}
          </p>
        </div>

        <ul className="flex grow flex-col justify-center gap-5">
          {translationKeys.map((translationKey) => {
            const delay =
              delaysByTranslationKey[
                translationKey as keyof typeof delaysByTranslationKey
              ];

            return (
              <li
                className={`${delay} ${displayListItems ? "opacity-100" : "opacity-0"} flex items-start gap-5 transition-opacity duration-1000 ease-in-out`}
                key={translationKey}
              >
                <CheckCircleIcon className="h-6 w-6 shrink-0 text-[#00C479]" />

                {t(`pages.onboarding.v1.whatYouGet.${translationKey}`)}
              </li>
            );
          })}
        </ul>

        <Form className="shrink-0" method="post">
          <Button title={t("shared.continue")} type="submit" />
        </Form>
      </div>
    </Layout>
  );
}
