import navigateToNextOnboardingPageAction from "@actions/navigateToNextOnboardingPageAction";
import AgePage from "@pages/onboarding/AgePage";
import LearningOpportunitiesPage from "@pages/onboarding/LearningOpportunitiesPage";
import LearnPage from "@pages/onboarding/LearnPage";
import ProductivityPage from "@pages/onboarding/ProductivityPage";
import TopicsPage from "@pages/onboarding/TopicsPage";
import LandingPage from "@pages/onboarding/v2/LandingPage";
import { Navigate, Outlet } from "react-router-dom";

const v2OnboardingRoutes = [
  {
    element: <Outlet />,
    path: "/onboarding/v2",
    children: [
      {
        index: true,
        element: <Navigate to="landing" replace />,
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <AgePage />,
        path: "age",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LandingPage />,
        path: "landing",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearnPage />,
        path: "learn",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearningOpportunitiesPage />,
        path: "learning-opportunities",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ProductivityPage />,
        path: "productivity",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TopicsPage />,
        path: "topics",
      },
    ],
  },
];

export default v2OnboardingRoutes;
