import { useLocation } from "react-router-dom";

export default function useIsFemaleOnboarding() {
  const location = useLocation();

  const isFemaleOnboardingRoute =
    location.pathname.includes("onboarding/female/");

  const isFemaleTheme = localStorage.getItem("theme") === "female";

  return isFemaleOnboardingRoute || isFemaleTheme;
}
