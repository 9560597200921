import navigateToNextOnboardingPageAction from "@actions/navigateToNextOnboardingPageAction";
import AgePage from "@pages/onboarding/AgePage";
import PersonalizeExperiencePage from "@pages/onboarding/v3/PersonalizeExperiencePage";
import { Navigate, Outlet } from "react-router-dom";

const v3OnboardingRoutes = [
  {
    element: <Outlet />,
    path: "/onboarding/v3",
    children: [
      {
        index: true,
        element: <Navigate to="age" replace />,
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <AgePage />,
        path: "age",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <PersonalizeExperiencePage />,
        path: "personalize-experience",
      },
    ],
  },
];

export default v3OnboardingRoutes;
