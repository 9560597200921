import Layout from "@components/Layout";
import RadioInputButton from "@components/RadioInputButton";
import { useTranslation } from "react-i18next";
import { Form, useSubmit } from "react-router-dom";

export default function YouTubeContentPage() {
  const submit = useSubmit();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form);
  };

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="heading">
            {t("pages.onboarding.female.youtubeContent.heading")}
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5"
          method="post"
        >
          <RadioInputButton
            emoji="🦄"
            label={t(
              "pages.onboarding.female.youtubeContent.inspirationalPodcasts",
            )}
            name="improve"
            onChange={handleChange}
            value="inspirational-podcasts"
          />

          <RadioInputButton
            emoji="🧠"
            label={t(
              "pages.onboarding.female.youtubeContent.mentalHealthTopics",
            )}
            name="improve"
            onChange={handleChange}
            value="mental-health-topics"
          />

          <RadioInputButton
            emoji="👩‍🎨"
            label={t("pages.onboarding.female.youtubeContent.diyCraftVideos")}
            name="improve"
            onChange={handleChange}
            value="diy-craft-videos"
          />

          <RadioInputButton
            emoji="🩺"
            label={t(
              "pages.onboarding.female.youtubeContent.healthAndWellness",
            )}
            name="improve"
            onChange={handleChange}
            value="health-and-wellness"
          />

          <RadioInputButton
            emoji="💄"
            label={t(
              "pages.onboarding.female.youtubeContent.fashionAndBeautyTips",
            )}
            name="improve"
            onChange={handleChange}
            value="fashion-and-beauty-tips"
          />
        </Form>
      </div>
    </Layout>
  );
}
