import Layout from "@components/Layout";
import RadioInputButton from "@components/RadioInputButton";
import { useTranslation } from "react-i18next";
import { Form, useSubmit } from "react-router-dom";

export default function UsagePage() {
  const submit = useSubmit();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form);
  };

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="heading">
            {" "}
            {t("pages.onboarding.youtube.usage.heading")}{" "}
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5"
          method="post"
        >
          <RadioInputButton
            className="border-custom-beige bg-custom-beige-transparent hover:bg-custom-beige has-[:checked]:bg-custom-beige"
            label={t("pages.onboarding.youtube.usage.learningNewSkills")}
            name="usage"
            onChange={handleChange}
            value="learning-new-skills"
          />

          <RadioInputButton
            className="border-custom-red bg-custom-red-transparent hover:bg-custom-red has-[:checked]:bg-custom-red"
            label={t("pages.onboarding.youtube.usage.entertainment")}
            name="usage"
            onChange={handleChange}
            value="entertainment"
          />

          <RadioInputButton
            className="border-custom-green bg-custom-green-transparent hover:bg-custom-green has-[:checked]:bg-custom-green"
            label={t(
              "pages.onboarding.youtube.usage.followingFavoriteChannels",
            )}
            name="usage"
            onChange={handleChange}
            value="following-favorite-channels"
          />

          <RadioInputButton
            className="border-custom-yellow bg-custom-yellow-transparent hover:bg-custom-yellow has-[:checked]:bg-custom-yellow"
            label={t("pages.onboarding.youtube.usage.researchAndInformation")}
            name="usage"
            onChange={handleChange}
            value="research-and-information"
          />

          <RadioInputButton
            className="border-custom-blue bg-custom-blue-transparent hover:bg-custom-blue has-[:checked]:bg-custom-blue"
            label={t("pages.onboarding.youtube.usage.other")}
            name="usage"
            onChange={handleChange}
            value="other"
          />
        </Form>
      </div>
    </Layout>
  );
}
