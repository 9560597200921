import youtubeLogoUrl from "@assets/youtube_logo.png";
import youtubeThumbnailUrl from "@assets/youtube_thumbnail.png";
import Button from "@components/Button";
import { Trans, useTranslation } from "react-i18next";
import { Form } from "react-router-dom";

export default function YouTubeSummaryPage() {
  const { t } = useTranslation();

  return (
    <main className="custom-container flex h-dvh flex-col justify-between space-y-14 overflow-y-auto pb-14 pt-8">
      <div>
        <img alt="YouTube" className="m-auto" src={youtubeLogoUrl} width={56} />

        <h1 className="heading my-6">
          {t("pages.onboarding.short.youtubeSummary.heading")}
        </h1>

        <div
          className="mx-auto aspect-video w-full max-w-xs rounded-3xl sm:max-w-xl"
          style={{
            backgroundImage: `url(${youtubeThumbnailUrl}`,
            backgroundPosition: "center bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        />

        <p className="mt-3 text-center font-bold">
          {t("pages.onboarding.short.youtubeSummary.historyOfTheEntireEarth")}
        </p>
      </div>

      <p className="text-center text-lg">
        <Trans
          components={{
            colored: <span className="theme-text-colored font-bold" />,
          }}
          i18nKey="pages.onboarding.youTubeCovered.text"
        />
      </p>

      <Form method="post">
        <Button title={t("shared.continue")} type="submit" />
      </Form>
    </main>
  );
}
