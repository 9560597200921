import {
  Description,
  Field,
  Input as HeadlessUiInput,
  Label,
} from "@headlessui/react";

interface Props {
  autoFocus?: boolean;
  description?: React.ReactNode | string;
  disabled?: boolean;
  invalid?: boolean;
  label?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
}

export default function Input({
  autoFocus = false,
  description,
  disabled = false,
  invalid = false,
  label,
  name,
  onChange,
  placeholder = "",
  type = "text",
}: Props) {
  return (
    <Field className="space-y-4 text-[#A2A4AE]">
      {label && (
        <Label className={`${invalid && "text-custom-error"} mx-7`}>
          {label}
        </Label>
      )}

      <HeadlessUiInput
        autoFocus={autoFocus}
        className="theme-input"
        disabled={disabled}
        invalid={invalid}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
      />

      {description && (
        <Description
          className={`${invalid && "text-custom-error"} mx-7 text-center text-xs`}
        >
          {description}
        </Description>
      )}
    </Field>
  );
}
