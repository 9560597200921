import Button from "@components/Button";
import Layout from "@components/Layout";
import RadioInputButton from "@components/RadioInputButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-router-dom";
import { ReactTyped, Typed } from "react-typed";
import { ThumbsDownOrUp } from "src/types";

export default function FullPotentialPage() {
  const [selected, setSelected] = useState<ThumbsDownOrUp | null>(null);
  const [typed, setTyped] = useState<Typed | null>(null);

  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selected = event.target.value;
    setSelected(selected as ThumbsDownOrUp);
    typed?.stop();
    typed?.start();
  };

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="heading">{t("pages.onboarding.potential.heading")}</h1>
        </div>

        <Form className="grow" method="post">
          <div className="flex h-full flex-col">
            <div className="flex grow flex-col justify-center space-y-5">
              <div className="grid w-full grid-cols-2 gap-5">
                <RadioInputButton
                  className="theme-button-input-thumbs-down p-8"
                  emoji="👎"
                  onChange={handleChange}
                  name="full-potential"
                  value="thumbsDown"
                />

                <RadioInputButton
                  className="theme-button-input-thumbs-up p-8"
                  emoji="👍"
                  onChange={handleChange}
                  name="full-potential"
                  value="thumbsUp"
                />
              </div>

              <div
                className={`${selected ? "visible opacity-100" : "invisible opacity-0"} theme-typed-text-box`}
              >
                <ReactTyped
                  startDelay={1000}
                  stopped
                  strings={[t("pages.onboarding.potential.text")]}
                  typeSpeed={10}
                  typedRef={setTyped}
                />
              </div>
            </div>

            <div className="shrink-0">
              <Button
                className={`${selected ? "visible opacity-100" : "invisible opacity-0"} theme-button-primary transition-opacity duration-1000 ease-in-out`}
                title={t("shared.continue")}
                type="submit"
              />
            </div>
          </div>
        </Form>
      </div>
    </Layout>
  );
}
