import navigateToNextOnboardingPageAction from "@actions/navigateToNextOnboardingPageAction";
import AgePage from "@pages/onboarding/AgePage";
import CompanyPage from "@pages/onboarding/CompanyPage";
import LearningOpportunitiesPage from "@pages/onboarding/LearningOpportunitiesPage";
import LearnPage from "@pages/onboarding/LearnPage";
import ProductivityPage from "@pages/onboarding/ProductivityPage";
import AiHelperPage from "@pages/onboarding/short/AiHelperPage";
import LearnFasterPage from "@pages/onboarding/short/LearnFasterPage";
import YouTubeSummaryPage from "@pages/onboarding/short/YouTubeSummaryPage";
import TopicsPage from "@pages/onboarding/TopicsPage";
import YouTubeOrPodcastsPage from "@pages/onboarding/YouTubeOrPodcastsPage";
import { Navigate, Outlet } from "react-router-dom";

const shortOnboardingRoutes = [
  {
    element: <Outlet />,
    path: "/onboarding/short",
    children: [
      {
        index: true,
        element: <Navigate to="age" replace />,
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <AgePage />,
        path: "age",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <AiHelperPage />,
        path: "ai-helper",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <CompanyPage />,
        path: "company",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearnPage />,
        path: "learn",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearnFasterPage />,
        path: "learn-faster",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearningOpportunitiesPage />,
        path: "learning-opportunities",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ProductivityPage />,
        path: "productivity",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TopicsPage />,
        path: "topics",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <YouTubeSummaryPage />,
        path: "youtube-summary",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <YouTubeOrPodcastsPage />,
        path: "youtube-or-podcasts",
      },
    ],
  },
];

export default shortOnboardingRoutes;
