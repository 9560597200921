import {
  addFacebookParamsToLocalStorage,
  addThemeToLocalStorage,
  applyThemeFromLocalStorage,
} from "@helpers/localStorage";
import useLogWebFunnel from "@hooks/useLogWebFunnel";
import LoadingPage from "@pages/LoadingPage";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

export default function RootOutlet() {
  const [isReady, setIsReady] = useState<boolean>(false);

  const location = useLocation();

  useLogWebFunnel();

  addThemeToLocalStorage(location.pathname);

  useEffect(() => {
    addFacebookParamsToLocalStorage(location.search);

    // NOTE: Initially wait one second to let the Facebook Pixel do its job and
    // add Facebook params to local storage.
    const setReadyWithTimeout = setTimeout(() => {
      setIsReady(true);
    }, 1000);

    return () => clearTimeout(setReadyWithTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // NOTE: Add theme to and apply theme from local storage on every route change.

  useEffect(() => {
    applyThemeFromLocalStorage();
  }, [location.pathname]);

  return <>{isReady ? <Outlet /> : <LoadingPage />}</>;
}
