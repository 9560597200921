import Dialog from "@components/Dialog";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  onClose: () => void;
  open: boolean;
}

export default function ForgotPasswordDialog({ onClose, open }: Props) {
  const { t } = useTranslation();

  return (
    <Dialog onClose={onClose} open={open}>
      <div className="space-y-10">
        <p className="heading">
          {t("pages.account.forgotPassword.dialog.title")}
        </p>

        <p className="text-center">
          <Trans
            components={{ bold: <span className="font-bold" /> }}
            i18nKey="pages.account.forgotPassword.dialog.text"
          />
        </p>
      </div>
    </Dialog>
  );
}
