import Layout from "@components/Layout";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRouteError } from "react-router-dom";

interface Error {
  message?: string;
  statusText?: string;
}

export default function ErrorPage() {
  const error = useRouteError() as Error;
  const { t } = useTranslation();

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  console.error(error);

  return (
    <Layout>
      <div className="flex h-full flex-col justify-center space-y-8">
        <h1 className="heading">{t("pages.error.oops")}</h1>

        <p className="theme-subheading">{t("pages.error.anErrorOccurred")}</p>

        <p className="text-center italic">
          {error?.statusText ?? error?.message}
        </p>
      </div>
    </Layout>
  );
}
