import { Confidence, visitorIdentity } from "@spotify-confidence/sdk";
import { redirect } from "react-router-dom";

const confidence = Confidence.create({
  clientSecret: import.meta.env.VITE_CONFIDENCE_CLIENT_SECRET as string,
  environment: "client",
  region: "eu",
  timeout: 1000,
});

export default async function getOnboardingFlagLoader() {
  confidence.track(visitorIdentity());

  const flagValue = await confidence.getFlag(
    "vm-web-onboarding-prosumer-short",
    {
      onboarding: "baseline",
    },
  );

  switch (flagValue.onboarding) {
    case "v1":
      return redirect("/onboarding/v1");
    case "v2":
      return redirect("/onboarding/v2");
    case "v3":
      return redirect("/onboarding/v3");
    default:
      return null;
  }
}
