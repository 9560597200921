import Button from "@components/Button";
import Layout from "@components/Layout";
import PasswordInput from "@components/PasswordInput";
import ResetPasswordDialog from "@components/ResetPasswordDialog";
import { updatePassword } from "@helpers/supabase";
import { validatePassword } from "@helpers/validations";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ResetPasswordPage() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");

  const { t } = useTranslation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { error } = await updatePassword({ password });

    if (error) {
      if (
        error.message ===
        "New password should be different from the old password."
      ) {
        setErrorMessage(
          t("pages.account.resetPassword.newPasswordShouldBeDifferent"),
        );
      } else {
        throw error;
      }
    } else {
      setIsDialogOpen(true);
    }
  };

  return (
    <>
      <Layout>
        <div className="flex h-full flex-col">
          <div className="grow space-y-6 text-center">
            <h1 className="heading">
              {t("pages.account.resetPassword.heading")}
            </h1>

            <p
              className={
                errorMessage ? "theme-subheading-error" : "theme-subheading"
              }
            >
              {errorMessage
                ? errorMessage
                : t("pages.account.resetPassword.enterNewPassword")}
            </p>
          </div>

          <form
            className="flex grow flex-col justify-between"
            onSubmit={handleSubmit}
          >
            <PasswordInput
              autoFocus
              onChange={(event) => setPassword(event.target.value)}
            />

            <Button
              disabled={!validatePassword(password)}
              title={t("pages.account.forgotPassword.resetPassword")}
              type="submit"
            />
          </form>
        </div>
      </Layout>

      {isDialogOpen && (
        <ResetPasswordDialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
        />
      )}
    </>
  );
}
