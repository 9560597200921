import navigateToNextOnboardingPageAction from "@actions/navigateToNextOnboardingPageAction";
import AgePage from "@pages/onboarding/AgePage";
import CompanyPage from "@pages/onboarding/CompanyPage";
import ComplexConceptsPage from "@pages/onboarding/ComplexConceptsPage";
import ImprovePage from "@pages/onboarding/female/ImprovePage";
import PersonalGrowthPage from "@pages/onboarding/female/PersonalGrowthPage";
import RelationshipChallengePage from "@pages/onboarding/female/RelationshipChallengePage";
import TopicsPage from "@pages/onboarding/female/TopicsPage";
import YouTubeContentPage from "@pages/onboarding/female/YouTubeContentPage";
import FullPotentialPage from "@pages/onboarding/FullPotentialPage";
import LearnFasterPage from "@pages/onboarding/LearnFasterPage";
import LearningOpportunitiesPage from "@pages/onboarding/LearningOpportunitiesPage";
import LearnPage from "@pages/onboarding/LearnPage";
import TailoredPage from "@pages/onboarding/TailoredPage";
import YouTubeCoveredPage from "@pages/onboarding/YouTubeCoveredPage";
import { Navigate, Outlet } from "react-router-dom";

const femaleOnboardingRoutes = [
  {
    element: <Outlet />,
    path: "/onboarding/female",
    children: [
      {
        index: true,
        element: <Navigate to="age" replace />,
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <AgePage />,
        path: "age",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <CompanyPage />,
        path: "company",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ComplexConceptsPage />,
        path: "complex-concepts",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <FullPotentialPage />,
        path: "full-potential",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearnPage />,
        path: "learn",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearnFasterPage />,
        path: "learn-faster",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearningOpportunitiesPage />,
        path: "learning-opportunities",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ImprovePage />,
        path: "improve",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <PersonalGrowthPage />,
        path: "personal-growth",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <RelationshipChallengePage />,
        path: "relationship-challenge",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TailoredPage />,
        path: "tailored",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TopicsPage />,
        path: "topics",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <YouTubeContentPage />,
        path: "youtube-content",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <YouTubeCoveredPage />,
        path: "youtube-covered",
      },
    ],
  },
];

export default femaleOnboardingRoutes;
