import Layout from "@components/Layout";
import RadioInputButton from "@components/RadioInputButton";
import { useTranslation } from "react-i18next";
import { Form, useSubmit } from "react-router-dom";

export default function ProductivityEnvironmentPage() {
  const submit = useSubmit();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form);
  };

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="heading">
            {t("pages.onboarding.youtube.productivityEnvironment.heading")}
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5"
          method="post"
        >
          <RadioInputButton
            className="border-custom-beige bg-custom-beige-transparent hover:bg-custom-beige has-[:checked]:bg-custom-beige"
            label={t(
              "pages.onboarding.youtube.productivityEnvironment.quietAndDistractionFree",
            )}
            onChange={handleChange}
            name="productivityEnvironment"
            value="quiet-and-distraction-free"
          />

          <RadioInputButton
            className="border-custom-red bg-custom-red-transparent hover:bg-custom-red has-[:checked]:bg-custom-red"
            label={t(
              "pages.onboarding.youtube.productivityEnvironment.energeticAndCollaborative",
            )}
            onChange={handleChange}
            name="productivityEnvironment"
            value="energetic-and-collaborative"
          />

          <RadioInputButton
            className="border-custom-green bg-custom-green-transparent hover:bg-custom-green has-[:checked]:bg-custom-green"
            label={t(
              "pages.onboarding.youtube.productivityEnvironment.structuredAndOrganized",
            )}
            onChange={handleChange}
            name="productivityEnvironment"
            value="structured-and-organized"
          />
        </Form>
      </div>
    </Layout>
  );
}
