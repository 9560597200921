import LayoutWithBackgroundImage from "@components/LayoutWithBackgroundImage";
import Spinner from "@components/Spinner";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactTyped } from "react-typed";

export default function PreparingPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate("/checkout/plans");
    }, 7000);

    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <LayoutWithBackgroundImage>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="heading">{t("pages.signUp.loading.heading")}</h1>
        </div>

        <div className="flex grow items-center justify-center">
          <div className="flex w-full max-w-xs flex-col items-center space-y-5 rounded-3xl bg-custom-purple-light p-8 text-center">
            <Spinner />

            <p className="text-xl font-bold">
              <ReactTyped
                fadeOut
                fadeOutDelay={500}
                strings={[
                  t("pages.signUp.loading.analyzingResponses"),
                  t("pages.signUp.loading.preparingInterface"),
                  t("pages.signUp.loading.creatingMindMapTopics"),
                ]}
                typeSpeed={40}
              />
            </p>
          </div>
        </div>
      </div>
    </LayoutWithBackgroundImage>
  );
}
