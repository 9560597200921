import appStoreAppOfTheYear2023White from "@assets/app_store_app_of_the_day_2023_white.png";
import googleEditorsChoice2023White from "@assets/google_editors_choice_2023_white.png";
import phoneDisplayingChatWithMindMap from "@assets/phone_chat_with_mind_map.png";
import phoneDisplayingGeneratedImage from "@assets/phone_generated_image.png";
import phoneDisplayingGeneratedMindMap from "@assets/phone_generated_mind_map.png";
import Button from "@components/Button";
import VisualMindLogoSvg from "@components/VisualMindLogoSvg";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Trans, useTranslation } from "react-i18next";
import { Form } from "react-router-dom";

export default function LandingPage() {
  const { t } = useTranslation();

  const translationKeys = [
    "chatWithAi",
    "youTubeSummary",
    "mindMaps",
    "linkSummary",
    "imageGenerator",
  ];

  return (
    <>
      <header className="custom-container sticky top-0 flex items-center justify-between bg-custom-black pb-4 pt-8">
        <VisualMindLogoSvg />

        <Form method="post">
          <Button
            className="!px-4 !py-2"
            title={t("pages.onboarding.v2.landing.startNow")}
            type="submit"
          ></Button>
        </Form>
      </header>

      <main className="custom-container pb-20 pt-12">
        <div className="grid grid-cols-1 gap-12 md:grid-cols-2 md:gap-32">
          <section className="text-center md:col-span-2">
            <h1 className="heading">
              <Trans
                components={{
                  colored: <span className="theme-text-colored font-bold" />,
                }}
                i18nKey="pages.onboarding.v2.landing.heading"
              />
            </h1>

            <p className="mt-4">
              {t("pages.onboarding.v2.landing.subheading")}
            </p>

            <Form className="mt-5" method="post">
              <Button
                className="!w-auto !px-16"
                title={t("pages.onboarding.v2.landing.startNow")}
                type="submit"
              />
            </Form>

            <ul className="mt-6">
              {translationKeys.map((translationKey) => {
                return (
                  <li
                    className="mx-2 my-3 inline-flex items-center gap-1"
                    key={translationKey}
                  >
                    <CheckCircleIcon className="size-6 text-[#00C479]" />

                    {t(`pages.onboarding.v2.landing.${translationKey}`)}
                  </li>
                );
              })}
            </ul>
          </section>

          <section className="text-center">
            <h2 className="heading text-custom-purple-light">
              {t("pages.onboarding.v2.landing.generateMindMapsHeading")}
            </h2>

            <p className="mt-4">
              {t("pages.onboarding.v2.landing.generateMindMapsText")}
            </p>

            <img
              alt={t("pages.onboarding.v2.landing.generateMindMapsImgAlt")}
              className="mt-8 px-8"
              src={phoneDisplayingGeneratedMindMap}
            />
          </section>

          <section className="text-center">
            <h2 className="heading text-custom-purple-light">
              {t("pages.onboarding.v2.landing.chatWithMindMapHeading")}
            </h2>

            <p className="mt-4">
              {t("pages.onboarding.v2.landing.chatWithMindMapText")}
            </p>

            <img
              alt={t("pages.onboarding.v2.landing.chatWithMindMapImgAlt")}
              className="mt-8 px-8"
              src={phoneDisplayingChatWithMindMap}
            />
          </section>

          <section className="md:col-span-2">
            <h2 className="heading">{t("pages.onboarding.company.heading")}</h2>

            <div className="mt-7 flex items-center justify-center space-x-5">
              <img
                alt={t("pages.onboarding.company.appStoreAppOfTheYear2023")}
                className="w-36"
                src={appStoreAppOfTheYear2023White}
              />

              <img
                alt={t("pages.onboarding.company.googleEditorsChoice2023")}
                className="w-36"
                src={googleEditorsChoice2023White}
              />
            </div>

            <div className="mt-6 space-y-4 text-center">
              <p className="text-lg">{t("pages.onboarding.company.andHas")}</p>

              <p className="heading">
                <Trans
                  components={{
                    colored: <span className="theme-text-colored" />,
                  }}
                  i18nKey="pages.onboarding.company.activeSubscribers"
                />
              </p>

              <p className="text-xl">⭐️⭐️⭐️⭐️⭐️</p>
            </div>
          </section>

          <section className="text-center">
            <h2 className="heading text-custom-purple-light">
              {t("pages.onboarding.v2.landing.youTubeVideoMindMapHeading")}
            </h2>

            <p className="mt-4">
              {t("pages.onboarding.v2.landing.youTubeVideoMindMapText")}
            </p>
          </section>

          <section className="text-center">
            <h2 className="heading text-custom-purple-light">
              {t("pages.onboarding.v2.landing.generateImagesHeading")}
            </h2>

            <img
              alt={t("pages.onboarding.v2.landing.generateImagesImgAlt")}
              className="mt-8 px-8"
              src={phoneDisplayingGeneratedImage}
            />
          </section>

          <section className="text-center md:col-span-2">
            <h2 className="heading text-custom-purple-light">
              {t("pages.onboarding.v2.landing.andSoMuchMore")}
            </h2>

            <Form className="mt-6" method="post">
              <Button
                className="!w-auto !px-16"
                title={t("pages.onboarding.v2.landing.startNow")}
                type="submit"
              />
            </Form>
          </section>
        </div>
      </main>
    </>
  );
}
