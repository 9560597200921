import Layout from "@components/Layout";
import ProgressBar from "@components/ProgressBar";
import RadioInputButton from "@components/RadioInputButton";
import { useTranslation } from "react-i18next";
import { Form, useSubmit } from "react-router-dom";

export default function ProductivityPage() {
  const submit = useSubmit();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form);
  };

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0 space-y-8">
          <ProgressBar />

          <h1 className="heading">
            {t("pages.onboarding.productivity.heading")}
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5"
          method="post"
        >
          <RadioInputButton
            className="v1-button-input"
            label={t("pages.onboarding.productivity.stayingFocused")}
            name="productivity"
            onChange={handleChange}
            value="staying-focused"
          />

          <RadioInputButton
            className="v1-button-input"
            label={t("pages.onboarding.productivity.timeManagement")}
            name="productivity"
            onChange={handleChange}
            value="time-management"
          />

          <RadioInputButton
            className="v1-button-input"
            label={t("pages.onboarding.productivity.maintainingMotivation")}
            name="productivity"
            onChange={handleChange}
            value="maintaining-motivation"
          />

          <RadioInputButton
            className="v1-button-input"
            label={t("pages.onboarding.productivity.taskOverload")}
            name="productivity"
            onChange={handleChange}
            value="task-overload"
          />

          <RadioInputButton
            className="v1-button-input"
            label={t("pages.onboarding.productivity.findingEffectiveTools")}
            name="productivity"
            onChange={handleChange}
            value="finding-effective-tools"
          />
        </Form>
      </div>
    </Layout>
  );
}
