import Button from "@components/Button";
import Layout from "@components/Layout";
import ProgressBar from "@components/ProgressBar";
import { useTranslation } from "react-i18next";
import { Form, useLocation } from "react-router-dom";

export default function SuccessFollowUpPage() {
  const location = useLocation();
  const { t } = useTranslation();

  const getEmoji = () => {
    switch (location.hash) {
      case "#achieving-milestones":
        return "🎯";
      case "#completing-projects":
        return "✅";
      case "#gaining-knowledge":
        return "🧠";
      case "#receiving-feedback":
        return "✍️";
      default:
        return "🧠";
    }
  };

  const getText = () => {
    switch (location.hash) {
      case "#achieving-milestones":
        return t("pages.onboarding.v1.successFollowUp.achievingMilestones");
      case "#completing-projects":
        return t("pages.onboarding.v1.successFollowUp.completingProjects");
      case "#gaining-knowledge":
        return t("pages.onboarding.v1.successFollowUp.gainingKnowledge");
      case "#receiving-feedback":
        return t("pages.onboarding.v1.successFollowUp.receivingFeedback");
      default:
        return t("pages.onboarding.v1.successFollowUp.gainingKnowledge");
    }
  };

  return (
    <Layout>
      <div className="flex h-full flex-col text-center">
        <div className="shrink-0">
          <ProgressBar />
        </div>

        <div className="flex grow flex-col items-center justify-center space-y-11">
          <span className="text-7xl">{getEmoji()}</span>

          <p className="text-lg">{getText()}</p>
        </div>

        <Form className="shrink-0" method="post">
          <Button title={t("shared.continue")} type="submit" />
        </Form>
      </div>
    </Layout>
  );
}
