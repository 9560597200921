import Layout from "@components/Layout";
import RadioInputButton from "@components/RadioInputButton";
import { useTranslation } from "react-i18next";
import { Form, useSubmit } from "react-router-dom";

export default function ProgressPage() {
  const submit = useSubmit();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form);
  };

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="heading">{t("pages.onboarding.progress.heading")}</h1>
        </div>

        <Form
          className="flex flex-grow flex-col justify-center space-y-5"
          method="post"
        >
          <RadioInputButton
            className="border-custom-green bg-custom-green-transparent hover:bg-custom-green has-[:checked]:bg-custom-green"
            label={t("pages.onboarding.progress.usingTools")}
            name="progress"
            onChange={handleChange}
            value="usingTools"
          />

          <RadioInputButton
            className="border-custom-blue bg-custom-blue-transparent hover:bg-custom-blue has-[:checked]:bg-custom-blue"
            label={t("pages.onboarding.progress.receivingFeedback")}
            name="progress"
            onChange={handleChange}
            value="recevingFeedback"
          />

          <RadioInputButton
            className="border-custom-red bg-custom-red-transparent hover:bg-custom-red has-[:checked]:bg-custom-red"
            label={t("pages.onboarding.progress.settingGoals")}
            name="progress"
            onChange={handleChange}
            value="settingGoals"
          />

          <RadioInputButton
            className="border-custom-yellow bg-custom-yellow-transparent hover:bg-custom-yellow has-[:checked]:bg-custom-yellow"
            label={t("pages.onboarding.progress.other")}
            name="progress"
            onChange={handleChange}
            value="other"
          />
        </Form>
      </div>
    </Layout>
  );
}
