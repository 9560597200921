import navigateToNextOnboardingPageAction from "@actions/navigateToNextOnboardingPageAction";
import CompanyPage from "@pages/onboarding/CompanyPage";
import ComplexConceptsPage from "@pages/onboarding/ComplexConceptsPage";
import FullPotentialPage from "@pages/onboarding/FullPotentialPage";
import LearnFasterPage from "@pages/onboarding/LearnFasterPage";
import LearningOpportunitiesPage from "@pages/onboarding/LearningOpportunitiesPage";
import ContentPage from "@pages/onboarding/youtube/ContentPage";
import EngagePage from "@pages/onboarding/youtube/EngagePage";
import OrganizingThoughtsPage from "@pages/onboarding/youtube/OrganizingThoughtsPage";
import ProductivityEnvironmentPage from "@pages/onboarding/youtube/ProductivityEnvironmentPage";
import UsagePage from "@pages/onboarding/youtube/UsagePage";
import YouTubeCoveredPage from "@pages/onboarding/YouTubeCoveredPage";
import { Navigate, Outlet } from "react-router-dom";

const youtubeOnboardingRoutes = [
  {
    element: <Outlet />,
    path: "/onboarding/youtube",
    children: [
      {
        index: true,
        element: <Navigate to="usage" replace />,
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <CompanyPage />,
        path: "company",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ComplexConceptsPage />,
        path: "complex-concepts",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ContentPage />,
        path: "content",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <YouTubeCoveredPage />,
        path: "covered",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <EngagePage />,
        path: "engage",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <FullPotentialPage />,
        path: "full-potential",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearnFasterPage />,
        path: "learn-faster",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <LearningOpportunitiesPage />,
        path: "learning-opportunities",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <OrganizingThoughtsPage />,
        path: "organizing-thoughts",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ProductivityEnvironmentPage />,
        path: "productivity-environment",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <UsagePage />,
        path: "usage",
      },
    ],
  },
];

export default youtubeOnboardingRoutes;
