import Layout from "@components/Layout";
import RadioInputButton from "@components/RadioInputButton";
import { useTranslation } from "react-i18next";
import { Form, useSubmit } from "react-router-dom";

export default function RelationshipChallengePage() {
  const submit = useSubmit();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form);
  };

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="heading">
            {t("pages.onboarding.female.relationshipChallenge.heading")}
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5"
          method="post"
        >
          <RadioInputButton
            emoji="❣️"
            label={t(
              "pages.onboarding.female.relationshipChallenge.buildingTrust",
            )}
            name="relationship-challenge"
            onChange={handleChange}
            value="building-trust"
          />

          <RadioInputButton
            emoji="🎙️"
            label={t(
              "pages.onboarding.female.relationshipChallenge.effectiveCommunication",
            )}
            name="relationship-challenge"
            onChange={handleChange}
            value="effective-communication"
          />

          <RadioInputButton
            emoji="⚖️"
            label={t(
              "pages.onboarding.female.relationshipChallenge.balancingPersonalSpace",
            )}
            name="relationship-challenge"
            onChange={handleChange}
            value="balancing-personal-space"
          />

          <RadioInputButton
            emoji="💎"
            label={t(
              "pages.onboarding.female.relationshipChallenge.managingExpectations",
            )}
            name="relationship-challenge"
            onChange={handleChange}
            value="managing-expectations"
          />

          <RadioInputButton
            emoji="🧐️"
            label={t(
              "pages.onboarding.female.relationshipChallenge.handlingConflicts",
            )}
            name="relationship-challenge"
            onChange={handleChange}
            value="handling-conflicts"
          />
        </Form>
      </div>
    </Layout>
  );
}
