import ButtonSpinner from "@components/ButtonSpinner";
import { Button as HeadlessUiButton } from "@headlessui/react";

interface Props {
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  submitting?: boolean;
  title: string;
  type?: "button" | "submit" | "reset";
}

export default function Button({
  className = "",
  disabled = false,
  onClick,
  submitting = false,
  title,
  type = "button",
}: Props) {
  return (
    <HeadlessUiButton
      className={`${className} theme-button-primary inline-flex justify-center gap-2`}
      disabled={disabled || submitting}
      onClick={onClick}
      type={type}
    >
      {submitting && <ButtonSpinner />}

      {title}
    </HeadlessUiButton>
  );
}
