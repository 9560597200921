import Button from "@components/Button";
import CheckboxInputButton from "@components/CheckboxInputButton";
import LayoutScrollable from "@components/LayoutScrollable";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-router-dom";

export default function TopicsPage() {
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);

  const { t } = useTranslation();

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    const topic = event.target.value;

    if (checked) {
      setSelectedTopics([...selectedTopics, topic]);
    } else {
      setSelectedTopics(
        selectedTopics.filter((selectedTopic) => selectedTopic !== topic),
      );
    }
  };

  return (
    <LayoutScrollable>
      <div className="sticky top-0 shrink-0 bg-female-background-transparent py-8">
        <h1 className="heading">{t("pages.onboarding.topics.heading")}</h1>
      </div>

      <Form className="flex grow flex-col" method="post">
        <div className="flex grow flex-col justify-center py-8">
          <div className="grid grid-cols-2 gap-5 pb-36">
            <CheckboxInputButton
              className="p-5 hover:!bg-white peer-checked:!bg-female-orange"
              emoji="💕️"
              id="navigating-modern-relationships"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t(
                "pages.onboarding.female.topics.navigatingModernRelationships",
              )}
            />

            <CheckboxInputButton
              className="p-5 hover:!bg-white peer-checked:!bg-female-orange"
              emoji="🧖‍♀️"
              id="self-care-and-wellness"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t("pages.onboarding.female.topics.selfCareAndWellness")}
            />

            <CheckboxInputButton
              className="p-5 hover:!bg-white peer-checked:!bg-female-orange"
              emoji="👩‍💻"
              id="mastering-productivity-hacks"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t(
                "pages.onboarding.female.topics.masteringProductivityHacks",
              )}
            />

            <CheckboxInputButton
              className="p-5 hover:!bg-white peer-checked:!bg-female-orange"
              emoji="👩‍🎨"
              id="creative-hobbies-and-crafts"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t(
                "pages.onboarding.female.topics.creativeHobbiesAndCrafts",
              )}
            />

            <CheckboxInputButton
              className="p-5 hover:!bg-white peer-checked:!bg-female-orange"
              emoji="🗺️"
              id="travel-and-lifestyle-tips"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t("pages.onboarding.female.topics.travelAndLifestyleTips")}
            />

            <CheckboxInputButton
              className="p-5 hover:!bg-white peer-checked:!bg-female-orange"
              emoji="📈️"
              id="personal-finance-management"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t(
                "pages.onboarding.female.topics.personalFinanceManagement",
              )}
            />

            <CheckboxInputButton
              className="p-5 hover:!bg-white peer-checked:!bg-female-orange"
              emoji="👩‍🔬"
              id="science-and-technology-trends"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t(
                "pages.onboarding.female.topics.scienceAndTechnologyTrends",
              )}
            />

            <CheckboxInputButton
              className="p-5 hover:!bg-white peer-checked:!bg-female-orange"
              emoji="⚖️"
              id="balancing-work-and-life"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t("pages.onboarding.female.topics.balancingWorkAndLife")}
            />

            <CheckboxInputButton
              className="p-5 hover:!bg-white peer-checked:!bg-female-orange"
              emoji="🤔"
              id="philosophical-insights-on-life"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t(
                "pages.onboarding.female.topics.philosophicalInsightsOnLife",
              )}
            />

            <CheckboxInputButton
              className="p-5 hover:!bg-white peer-checked:!bg-female-orange"
              emoji="🧠"
              id="understanding-emotional-intelligence"
              name="topics"
              onChange={handleCheckBoxChange}
              title={t(
                "pages.onboarding.female.topics.understandingEmotionalIntelligence",
              )}
            />
          </div>
        </div>

        <div className="sticky bottom-14 shrink-0 bg-transparent">
          <Button
            disabled={selectedTopics.length === 0}
            title={t("shared.continue")}
            type="submit"
          />
        </div>
      </Form>
    </LayoutScrollable>
  );
}
